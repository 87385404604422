import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import AlexGorgiasPhoto from '../assets/images/quotes/alex-plugaru.png'
import quoteBackgroundImage1x from '../assets/images/quotes/background-1x.png'
import QuoteFrame from '../assets/images/quotes/quote-picture-frame.png'
import SvyatWoltPhoto from '../assets/images/quotes/svyat.png'
import theme from '../theme'

function QuoteAvatar({ photoUrl, alt }: { photoUrl: string; alt?: string }) {
  return (
    <QuoteAvatarContainer>
      <img className="quote-picture" src={photoUrl} alt={alt} />
      <img className="quote-picture-frame" src={QuoteFrame} />
    </QuoteAvatarContainer>
  )
}

const QuoteAvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 148px;
  width: 163px;
  margin-right: 48px;

  img {
    position: absolute;
  }

  .quote-picture-frame {
    height: inherit;
    width: inherit;
  }

  .quote-picture {
    height: 120px;
    width: 120px;
  }
`

const quotes = {
  gorgias: {
    content:
      'With Swarmia we’re able to highlight problem areas, set target levels, and drive continuous improvement.',
    name: 'Alex Plugaru',
    title: 'Co-Founder & CTO, Gorgias',
    link: '/story/gorgias',
    photoUrl: AlexGorgiasPhoto,
  },
  wolt: {
    content: `
      Swarmia's Slack notifications make it really easy to keep track of open pull requests across repositories.
      Even if I can't immediately review a teammate's code, the Slack notifications help me catch up on the comments
      of the right PR as soon as I have time.`,
    name: 'Svyat Sobol',
    title: 'Lead Engineer, Wolt',
    link: '/story/wolt',
    photoUrl: SvyatWoltPhoto,
  },
}

type CustomerName = keyof typeof quotes

function CustomerQuote({ quote }: { quote: CustomerName }) {
  const { content, name, title, link, photoUrl } = quotes[quote]
  return (
    <QuoteContainer>
      <QuoteAvatar photoUrl={photoUrl} alt={name} />
      <div className="quote-text">
        <h2 className="quote-content">“{content}”</h2>
        <h6>
          <Link to={link}>Read the case study →</Link>
        </h6>
        <div className="person-details">
          <QuoteAvatar photoUrl={photoUrl} alt={name} />
          <div>
            <div className="name">
              <b>{name}</b>
            </div>
            <div className="title">{title}</div>
          </div>
        </div>
      </div>
    </QuoteContainer>
  )
}

const QuoteContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.space[10]};
  color: #ffffff;
  padding: 92px 0;

  background-image: url(${quoteBackgroundImage1x});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  background-color: #000000;

  .quote-text {
    h2 {
      font-size: 36px;
      font-weight: 200;
      color: #ffffff;
      max-width: 800px;
      margin: 32px 0;
      color: inherit;
    }

    a {
      color: inherit;
      text-transform: uppercase;
      font-weight: 700;
      padding-bottom: 4px;
      border-bottom: 2px solid transparent;
      color: white;
    }

    a:hover {
      border-bottom: 2px solid ${theme.colors.dataPink};
    }
  }
  .person-details {
    .name {
      color: white;
    }
    .title {
      color: ${theme.colors.dataGrey};
    }

    ${QuoteAvatarContainer} {
      display: none;
    }

    @media screen and (max-width: ${theme.breakpoints['medium']}) {
      margin-top: 32px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      ${QuoteAvatarContainer} {
        display: inherit;
        margin: 0 32px 0 0;
      }
    }
  }

  @media screen and (max-width: ${theme.breakpoints['medium']}) {
    display: inline-block;
    margin: 0px;
    padding: 32px;
  }

  @media screen and (max-width: ${theme.breakpoints['medium']}) {
    ${QuoteAvatarContainer} {
      display: none;
    }
  }
`

export default CustomerQuote
