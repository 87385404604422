import React from 'react'
import styled from 'styled-components'
import CustomerQuote from '../components/CustomerQuote'
import DemoForm from '../components/form/DemoForm'
import G2AndCustomerLogos from '../components/G2AndCustomerLogos'
import Layout from '../components/Layout'

const DemoRequestPage = () => {
  return (
    <Layout
      justifyCenter
      title="Get a demo"
      description="See Swarmia in action with a live demo from one of our product experts."
    >
      <Container>
        <div className="columns">
          <div className="col">
            <Intro />
          </div>
          <div className="col">
            <DemoForm formId="ffb0c96d-725a-4964-968b-c11221a10004" />
          </div>
        </div>
        <G2AndCustomerLogos />
        <CustomerQuote quote="gorgias" />
      </Container>
    </Layout>
  )
}

const Intro = () => {
  return (
    <IntroStyled>
      <div>
        <h1>See Swarmia in action with a live demo</h1>
        <div className="text-tight">
          <p>
            Schedule a demo to see why modern software teams choose Swarmia to
            improve engineering productivity and developer experience.
          </p>
        </div>
        <br />
      </div>
    </IntroStyled>
  )
}

const IntroStyled = styled.div`
  width: 410px;
  text-align: left;
  .sub-heading {
    p {
      margin-top: 12px;
      margin-bottom: 35px;
      max-width: 400px;
    }
  }

  @media screen and (max-width: 981px) {
    width: auto;
  }
`

const Container = styled.div`
  margin-top: 64px;

  .columns {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-bottom: 24px;
    max-width: 1280px;
  }
  .col {
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 560px;
  }

  & h3 {
    opacity: 0.5;
    font-weight: 500;
  }

  // Show only one column if mobile
  @media screen and (max-width: 981px) {
    .columns {
      flex-direction: column;
      .col {
        width: 100%;
        max-width: 100%;
      }
    }
  }
`

export default DemoRequestPage
